.sample-img-wrap {
    @apply overflow-hidden max-w-xxxs;
    img {
        @apply transition-all duration-500 ease-in-out;
    }
    @screen lg {
        max-width: 270px;
    }
    &:hover {
        img {
            transform: scale(1.1);
        }
    }
}

.sample-wrap {
    &:hover {
        @apply no-underline;
    }
}

.sample-heading-wrap {
    @apply w-11/12 mx-auto mb-2 pb-4 border-b-2 border-gray-400 text-center;
    @screen md {
        @apply pr-8;
    }
    @screen lg {
        @apply w-64 mr-8 ml-0 mb-0 pb-0 border-b-0 border-r-2 text-left;
    }

}


.sample-content-wrap {
    @apply flex flex-wrap items-center w-11/12 max-w-xl mx-auto my-8;
    @screen md {
        @apply justify-start;
    }
    @screen lg {
        @apply flex-no-wrap w-192 max-w-full;
    }
}
