.embed-wrap {
    @apply w-full max-w-5xl mx-auto;
}

.embed-container {
    @apply relative h-auto bg-black overflow-hidden;

    &-21-9 {
        padding-top: 42.86%;
    }
    &-18-9 {
        padding-top: 50%;
    }
    &-16-9 {
        padding-top: 56.25%;
    }
    &-4-3 {
        padding-top: 75%;
    }

    iframe,
    object,
    embed {
        @apply absolute top-0 left-0 right-0 w-full h-full max-w-full mx-auto;
    }
}
