.about-wrap {
    @apply w-11/12 mx-auto p-8 bg-blue-700 text-white;
    @screen lg {
        @apply w-128 mx-0;
    }
    a {
        @apply text-white underline;
        &:hover {
            @apply text-gray-500;
        }
    }
}
