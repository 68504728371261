// style all form controls/inputs
[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
    @apply w-full px-2 py-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
    &:focus {
        @apply border-gray-500 bg-gray-100;
    }
}
textarea {
    // fixes space below textareas, making it consistent across browsers
    @apply align-top text-black;
}
select {
    @apply w-full p-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
    height: 2.14rem;
    &:focus {
        @apply border-gray-500 bg-gray-100;
    }
}
[type=checkbox] {
    @apply mr-2;
}
// fixes alignment of radio to label
[type=radio] {
    @apply relative mr-1;
    top: -1px;
}
// make sure everything shows a pointer on hover
[type=submit], [type=image], label, select {
    @apply cursor-pointer;
}
[type=file] {
    @apply w-full;
}

.form-wrap {
    @apply w-11/12 max-w-lg mx-auto my-4 p-4 border border-gray-400 shadow-md;
}

.field-wrap {
    @apply mb-4;
    label {
        @apply block mb-1;
    }
}
// flex-wrap will ensure help is on a separate line
.field-wrap-checkbox {
    @apply flex flex-wrap items-center mb-4;
    label {
        @apply inline-block mr-4 mb-0;
    }
}

.field-wrap-radio {
    @apply mb-1;
}

.field-wrap-radios, .field-wrap-checkboxes {
    @apply mb-4;
    label {
        @apply inline-block mr-4 mb-0;
    }
    legend {
        @apply mb-1;
    }
    .field-wrap-checkbox {
        @apply mb-1;
    }
}

.field-errors {
    @apply mb-1 pl-0 list-none;
    li {
        @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
    }
}

.field-wrap-readonly {
    @apply relative;

    &:after {
        @apply absolute right-0 text-xs opacity-50;
        top: 0.5rem;
        content: "read-only";
    }

    input {
        @apply opacity-50;
    }
}

// 100% width wraps help when within a flexbox (ex. for a checkbox).
.field-help {
    @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
    // the padding helps it align better with field above
    padding-left: 0.1em;
}

// these show up after the form buttons, outside the form tag
// for extra actions, such as delete, that aren't part of the current form
// but also would add clutter to add them as links on the record list
// this is typically a <ul>
// the actions here may or may not be inside a form
.form-extra_actions {
    @apply flex flex-col flex-wrap items-end justify-end list-none;
}
.form-action {
    @apply text-sm ml-4;
}
