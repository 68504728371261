/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@import "common";

// global styles on the most common elements
html {
    @apply font-body leading-normal text-gray-800;
}

// common styles
@import "alerts",
    "content_alignment",
    "forms",
    "media",
    "embed",
    "modal",
    "headings";

// public only styles
@import "content",
    "buttons",
    "header",
    "herobar",
    "samples";

// page specific
@import "p_index",
"p_contact",
"faq";

@tailwind utilities;
