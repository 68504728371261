.header-top {
    background: linear-gradient(360deg, rgb(57, 106, 140) 0%, rgba(83,139,176,1) 100%);
}

.nav-wrap {
    @apply flex flex-col items-center py-4;
    @screen lg {
        @apply flex-row justify-between;
    }
}

.header-nav_item {
    @apply hidden mr-8 mb-0 font-bold text-lg;
    a, button {
        @apply text-blue-800;
    }
    &:last-of-type {
        @apply mr-0;
    }
}

.header-nav_item-replace {
    span {
        @apply hidden;
        @screen sm {
            @apply inline;
        }
    }
}

$menu-items: (
    contact : 33,
    more : 1,
    replace : 20,
    vinyl : 25,
    prep : 50,
    gallery : 55,
    faq: 55,
);

@each $name, $mq-width in $menu-items {
    .header-nav_item-#{$name} {
        @include mq($mq-width * 1em) { display: inline-block; }
    }
}

.header-nav_item-contact {
    @screen lg {
        @apply mr-0;
    }
}
.header-nav_item-more {
    @media (min-width: 55rem) {
        @apply hidden;
    }
}

.header_mobile-open {
    @apply block;
    ul {
        @apply block mt-0 text-center text-lg;
    }
    a.header-menu {
        &:hover, &:focus, &:active {
            @apply no-underline text-white;
        }
    }
    .header-nav_item {
        @apply block mr-0 border-b border-gray-300 py-4 leading-normal;
        //transition: all 0.25s ease-in-out;
        &:hover {
            @apply bg-gray-100;
        }
        &:last-of-type {
            @apply border-0;
        }
        &-more {
            @apply hidden;
        }
        a {
            @apply block w-full;
        }
    }
    .header-nav_item-replace {
        span {
            @apply inline;
        }
    }
}
