.faq-item {
    @apply mb-8 pb-8 border-b-2 border-gray-200;
    &:last-of-type {
        @apply border-b-0;
    }
    p:first-of-type {
        @apply mt-0;
    }
    p:last-of-type {
        @apply mb-0;
    }
}
