// Leading resets tailwinds default line-height
button, .button {
    @apply font-headings py-2 px-4 text-white text-lg bg-blue-700 shadow-none border border-blue-700 rounded leading-tight transition-all duration-300 ease-in-out;
    &:hover {
        @apply text-white bg-blue-800 border-blue-800 no-underline transition-all duration-300 ease-in-out;
    }
    &-large {
        @apply border-4 pt-5 pb-4 px-8 text-xl font-bold;
    }
    &-transparent {
        @apply text-blue-700 bg-white-transparent;
        &:hover {
            @apply text-blue-700 bg-white-transparent-dark;
        }
    }
}

a.button {
    @apply inline-block;
}

.button-link {
    @apply font-body p-0 border-none bg-transparent text-base font-bold;
    &:hover {
        @apply border-none bg-transparent;
    }
}
