$mqs: ();
@mixin mq($breakpoint) {
    @if map-has-key($mqs, $breakpoint) {
        @media (min-width: #{map-get($mqs, $breakpoint)}) { @content; }
    }
    @else {
        @media (min-width: $breakpoint) { @content }
    }
}

// @todo move to tailwind.js?
@mixin mq_retina() {
    @media
        (-webkit-min-device-pixel-ratio: 2),
        (min-resolution: 192dpi) {
            @content
        }
}
