.herobar {
    @apply max-w-8xl h-64 mx-auto bg-center bg-cover;
    background-image: url('/images/calgary-deck-protection-sm.jpg');
    @screen md {
        @apply h-96;
        background-image: url('/images/calgary-deck-protection.jpg');
    }
    &-home {
        @apply bg-top h-64;
        background-image: url('/images/calgary-weatherdek-banner-sm.jpg');
        @screen md {
            @apply h-128;
            background-image: url('/images/calgary-weatherdek-banner.jpg');
        }
    }
}

.herobar-inner {
    @apply flex flex-col justify-end h-64;
    @screen md {
        @apply h-96;
    }
    background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 37%, rgba(0,0,0,0.65) 100%);
    &-home {
        @apply h-64;
        @screen md {
            @apply h-128;
        }
    }
}
