.w-content, .w-content-narrow, .w-content-copy, .w-content-wide {
    @apply max-w-11/12 mx-auto;
}
.w-content {
    @screen xl {
        @apply max-w-6xl;
    }
}
.w-content-narrow {
    @screen md {
        @apply max-w-lg;
    }
    @screen xl {
        @apply max-w-3xl;
    }
}
.w-content-copy {
    @screen md {
        @apply max-w-md;
    }
    @screen xl {
        @apply max-w-xl;
    }
}
.w-content-wide {
    @screen xl {
        @apply max-w-8xl;
    }
}

.bg-web {
    background-image: url('/images/bkg-prism.png');
}

.bg-lines {
    background-color: #4a7698;
    background-image: url('/images/bg-lines.png');
}
