/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */
/* this file is for public and private/admin pages */
a, .button-link {
  @apply text-blue-700 no-underline transition-all duration-300 ease-in-out;
}

a:hover, a:active, .button-link:hover, .button-link:active {
  @apply underline text-blue-500;
}

a:disabled, .button-link:disabled {
  @apply opacity-75;
}

a:disabled:hover, .button-link:disabled:hover {
  @apply no-underline cursor-default;
}

.button-link {
  @apply font-light;
}

b, strong {
  @apply font-bold;
}

ul, ol {
  @apply pl-10;
}

ul li, ol li {
  @apply mb-4;
}

ul li:last-of-type, ol li:last-of-type {
  @apply mb-0;
}

ul {
  @apply list-disc;
}

ol {
  @apply list-decimal;
}

address {
  @apply not-italic;
}

td {
  @apply align-top;
}

.transition {
  @apply transition-all duration-300 ease-in-out;
}

html {
  @apply font-body leading-normal text-gray-800;
}

.alert {
  @apply flex justify-between mt-0 mx-auto mb-1 p-2 bg-gray-200 border-l-10;
}

.form-wrap .alert {
  @apply w-full;
}

.alert-success {
  @apply border-green-500;
}

.alert-info {
  @apply border-blue-400;
}

.alert-warning {
  @apply border-orange-500;
}

.alert-danger {
  @apply border-red-500;
}

.content-img-center {
  @apply block mx-auto my-4;
}

.content-img-left {
  @apply float-left my-4 mr-4;
}

.content-img-right {
  @apply float-right my-4 ml-4;
}

[type=text],
[type=password],
[type=date],
[type=datetime],
[type=datetime-local],
[type=month],
[type=week],
[type=email],
[type=number],
[type=search],
[type=tel],
[type=time],
[type=url],
[type=color],
textarea {
  @apply w-full px-2 py-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
}

[type=text]:focus,
[type=password]:focus,
[type=date]:focus,
[type=datetime]:focus,
[type=datetime-local]:focus,
[type=month]:focus,
[type=week]:focus,
[type=email]:focus,
[type=number]:focus,
[type=search]:focus,
[type=tel]:focus,
[type=time]:focus,
[type=url]:focus,
[type=color]:focus,
textarea:focus {
  @apply border-gray-500 bg-gray-100;
}

textarea {
  @apply align-top text-black;
}

select {
  @apply w-full p-1 text-black border border-gray-400 rounded-sm bg-gray-200 transition-colors duration-300 ease-in-out;
  height: 2.14rem;
}

select:focus {
  @apply border-gray-500 bg-gray-100;
}

[type=checkbox] {
  @apply mr-2;
}

[type=radio] {
  @apply relative mr-1;
  top: -1px;
}

[type=submit], [type=image], label, select {
  @apply cursor-pointer;
}

[type=file] {
  @apply w-full;
}

.form-wrap {
  @apply w-11/12 max-w-lg mx-auto my-4 p-4 border border-gray-400 shadow-md;
}

.field-wrap {
  @apply mb-4;
}

.field-wrap label {
  @apply block mb-1;
}

.field-wrap-checkbox {
  @apply flex flex-wrap items-center mb-4;
}

.field-wrap-checkbox label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radio {
  @apply mb-1;
}

.field-wrap-radios, .field-wrap-checkboxes {
  @apply mb-4;
}

.field-wrap-radios label, .field-wrap-checkboxes label {
  @apply inline-block mr-4 mb-0;
}

.field-wrap-radios legend, .field-wrap-checkboxes legend {
  @apply mb-1;
}

.field-wrap-radios .field-wrap-checkbox, .field-wrap-checkboxes .field-wrap-checkbox {
  @apply mb-1;
}

.field-errors {
  @apply mb-1 pl-0 list-none;
}

.field-errors li {
  @apply mb-1 p-2 bg-gray-200 border-l-10 border-red-700;
}

.field-wrap-readonly {
  @apply relative;
}

.field-wrap-readonly:after {
  @apply absolute right-0 text-xs opacity-50;
  top: 0.5rem;
  content: "read-only";
}

.field-wrap-readonly input {
  @apply opacity-50;
}

.field-help {
  @apply w-full mt-px text-xs italic text-gray-600 leading-tight;
  padding-left: 0.1em;
}

.form-extra_actions {
  @apply flex flex-col flex-wrap items-end justify-end list-none;
}

.form-action {
  @apply text-sm ml-4;
}

.embed-wrap {
  @apply w-full max-w-5xl mx-auto;
}

.embed-container {
  @apply relative h-auto bg-black overflow-hidden;
}

.embed-container-21-9 {
  padding-top: 42.86%;
}

.embed-container-18-9 {
  padding-top: 50%;
}

.embed-container-16-9 {
  padding-top: 56.25%;
}

.embed-container-4-3 {
  padding-top: 75%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  @apply absolute top-0 left-0 right-0 w-full h-full max-w-full mx-auto;
}

.md-enter-active,
.md-leave-active {
  @apply transition-all duration-500;
}

.md-enter,
.md-leave-active {
  @apply opacity-0;
  transform: translateY(24px);
}

h1, h2, h3, h4, h5, h6 {
  @apply font-headings leading-tight font-bold;
}

h1 {
  @apply text-4xl;
  margin: 0.67rem 0;
}

@screen lg {
  h1 {
    @apply text-5xl;
  }
}

h2 {
  @apply text-3xl text-gray-700;
  margin: 0.83rem 0;
}

@screen lg {
  h2 {
    @apply text-4xl;
  }
}

h3 {
  @apply text-2xl mt-6 mb-2;
}

@screen lg {
  h3 {
    @apply text-3xl;
  }
}

h4 {
  @apply text-lg mt-6 mb-2;
}

h5 {
  @apply text-sm;
  margin: 1.67rem 0;
}

h6 {
  @apply text-xs mt-6 mb-2;
}

h3 + p, h4 + p {
  @apply mt-0;
}

h1 a, h2 a, h3 a, h4 a {
  @apply no-underline text-inherit;
}

h1 a:hover, h1 a:active, h2 a:hover, h2 a:active, h3 a:hover, h3 a:active, h4 a:hover, h4 a:active {
  @apply underline text-inherit;
}

p {
  @apply my-4;
}

.w-content, .w-content-narrow, .w-content-copy, .w-content-wide {
  @apply max-w-11/12 mx-auto;
}

@screen xl {
  .w-content {
    @apply max-w-6xl;
  }
}

@screen md {
  .w-content-narrow {
    @apply max-w-lg;
  }
}

@screen xl {
  .w-content-narrow {
    @apply max-w-3xl;
  }
}

@screen md {
  .w-content-copy {
    @apply max-w-md;
  }
}

@screen xl {
  .w-content-copy {
    @apply max-w-xl;
  }
}

@screen xl {
  .w-content-wide {
    @apply max-w-8xl;
  }
}

.bg-web {
  background-image: url("/images/bkg-prism.png");
}

.bg-lines {
  background-color: #4a7698;
  background-image: url("/images/bg-lines.png");
}

button, .button {
  @apply font-headings py-2 px-4 text-white text-lg bg-blue-700 shadow-none border border-blue-700 rounded leading-tight transition-all duration-300 ease-in-out;
}

button:hover, .button:hover {
  @apply text-white bg-blue-800 border-blue-800 no-underline transition-all duration-300 ease-in-out;
}

button-large, .button-large {
  @apply border-4 pt-5 pb-4 px-8 text-xl font-bold;
}

button-transparent, .button-transparent {
  @apply text-blue-700 bg-white-transparent;
}

button-transparent:hover, .button-transparent:hover {
  @apply text-blue-700 bg-white-transparent-dark;
}

a.button {
  @apply inline-block;
}

.button-link {
  @apply font-body p-0 border-none bg-transparent text-base font-bold;
}

.button-link:hover {
  @apply border-none bg-transparent;
}

.header-top {
  background: linear-gradient(360deg, #396a8c 0%, #538bb0 100%);
}

.nav-wrap {
  @apply flex flex-col items-center py-4;
}

@screen lg {
  .nav-wrap {
    @apply flex-row justify-between;
  }
}

.header-nav_item {
  @apply hidden mr-8 mb-0 font-bold text-lg;
}

.header-nav_item a, .header-nav_item button {
  @apply text-blue-800;
}

.header-nav_item:last-of-type {
  @apply mr-0;
}

.header-nav_item-replace span {
  @apply hidden;
}

@screen sm {
  .header-nav_item-replace span {
    @apply inline;
  }
}

@media (min-width: 33em) {
  .header-nav_item-contact {
    display: inline-block;
  }
}

@media (min-width: 1em) {
  .header-nav_item-more {
    display: inline-block;
  }
}

@media (min-width: 20em) {
  .header-nav_item-replace {
    display: inline-block;
  }
}

@media (min-width: 25em) {
  .header-nav_item-vinyl {
    display: inline-block;
  }
}

@media (min-width: 50em) {
  .header-nav_item-prep {
    display: inline-block;
  }
}

@media (min-width: 55em) {
  .header-nav_item-gallery {
    display: inline-block;
  }
}

@media (min-width: 55em) {
  .header-nav_item-faq {
    display: inline-block;
  }
}

@screen lg {
  .header-nav_item-contact {
    @apply mr-0;
  }
}

@media (min-width: 55rem) {
  .header-nav_item-more {
    @apply hidden;
  }
}

.header_mobile-open {
  @apply block;
}

.header_mobile-open ul {
  @apply block mt-0 text-center text-lg;
}

.header_mobile-open a.header-menu:hover, .header_mobile-open a.header-menu:focus, .header_mobile-open a.header-menu:active {
  @apply no-underline text-white;
}

.header_mobile-open .header-nav_item {
  @apply block mr-0 border-b border-gray-300 py-4 leading-normal;
}

.header_mobile-open .header-nav_item:hover {
  @apply bg-gray-100;
}

.header_mobile-open .header-nav_item:last-of-type {
  @apply border-0;
}

.header_mobile-open .header-nav_item-more {
  @apply hidden;
}

.header_mobile-open .header-nav_item a {
  @apply block w-full;
}

.header_mobile-open .header-nav_item-replace span {
  @apply inline;
}

.herobar {
  @apply max-w-8xl h-64 mx-auto bg-center bg-cover;
  background-image: url("/images/calgary-deck-protection-sm.jpg");
}

@screen md {
  .herobar {
    @apply h-96;
    background-image: url("/images/calgary-deck-protection.jpg");
  }
}

.herobar-home {
  @apply bg-top h-64;
  background-image: url("/images/calgary-weatherdek-banner-sm.jpg");
}

@screen md {
  .herobar-home {
    @apply h-128;
    background-image: url("/images/calgary-weatherdek-banner.jpg");
  }
}

.herobar-inner {
  @apply flex flex-col justify-end h-64;
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 37%, rgba(0, 0, 0, 0.65) 100%);
}

@screen md {
  .herobar-inner {
    @apply h-96;
  }
}

.herobar-inner-home {
  @apply h-64;
}

@screen md {
  .herobar-inner-home {
    @apply h-128;
  }
}

.sample-img-wrap {
  @apply overflow-hidden max-w-xxxs;
}

.sample-img-wrap img {
  @apply transition-all duration-500 ease-in-out;
}

@screen lg {
  .sample-img-wrap {
    max-width: 270px;
  }
}

.sample-img-wrap:hover img {
  transform: scale(1.1);
}

.sample-wrap:hover {
  @apply no-underline;
}

.sample-heading-wrap {
  @apply w-11/12 mx-auto mb-2 pb-4 border-b-2 border-gray-400 text-center;
}

@screen md {
  .sample-heading-wrap {
    @apply pr-8;
  }
}

@screen lg {
  .sample-heading-wrap {
    @apply w-64 mr-8 ml-0 mb-0 pb-0 border-b-0 border-r-2 text-left;
  }
}

.sample-content-wrap {
  @apply flex flex-wrap items-center w-11/12 max-w-xl mx-auto my-8;
}

@screen md {
  .sample-content-wrap {
    @apply justify-start;
  }
}

@screen lg {
  .sample-content-wrap {
    @apply flex-no-wrap w-192 max-w-full;
  }
}

.about-wrap {
  @apply w-11/12 mx-auto p-8 bg-blue-700 text-white;
}

@screen lg {
  .about-wrap {
    @apply w-128 mx-0;
  }
}

.about-wrap a {
  @apply text-white underline;
}

.about-wrap a:hover {
  @apply text-gray-500;
}

.disclaimer-wrap {
  @apply flex mt-12 text-sm italic;
}

.disclaimer-wrap p:first-of-type {
  @apply mt-0;
}

.faq-item {
  @apply mb-8 pb-8 border-b-2 border-gray-200;
}

.faq-item:last-of-type {
  @apply border-b-0;
}

.faq-item p:first-of-type {
  @apply mt-0;
}

.faq-item p:last-of-type {
  @apply mb-0;
}

@tailwind utilities;
